var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from "react-query";
import { RunnerClass, } from "./types";
export const API_URL = __API_URL__;
export function useTruck(id) {
    const { data } = useQuery(["trucks", id], query(`${API_URL}/trucks/${id}`), { enabled: !!id, staleTime: 100, refetchInterval: 100 });
    return data;
}
export function doTruckAction(truckId, truckVersion, action) {
    return fetch(`${API_URL}/trucks/${truckId}/actions`, {
        body: JSON.stringify({ version: truckVersion, action }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
    });
}
export function runnerClassToGender(runnerClass) {
    switch (runnerClass) {
        case RunnerClass.Men:
            return "men";
        case RunnerClass.Women:
            return "women";
        case RunnerClass.MU19:
            return "mj";
        case RunnerClass.WU19:
            return "wj";
        case RunnerClass.MU23:
            return "mu";
        case RunnerClass.MU23Crit:
            return "mu-crit";
        case RunnerClass.MenCrit:
            return "men-crit";
        case RunnerClass.WomenCrit:
            return "women-crit";
    }
}
export function useSupers() {
    const { data } = useQuery(["supers"], query(`${API_URL}/supers`), {
        staleTime: 500,
        refetchInterval: 500,
    });
    return data !== null && data !== void 0 ? data : [];
}
export function useRunnersList(gender) {
    const { data } = useQuery(["athletes", { gender }], query(gender
        ? `${API_URL}/athletes?gender=${gender}`
        : `${API_URL}/athletes`), { enabled: !!gender, staleTime: 60 * 1000, refetchInterval: 60 * 1000 });
    return data !== null && data !== void 0 ? data : [];
}
export function useRunnerDetails(id) {
    const { data } = useQuery(["athletes", id], query(`${API_URL}/athletes/${id}`), {
        enabled: !!id,
        refetchInterval: 5 * 1000,
    });
    return data;
}
export function useCourseMap(gender) {
    const { data } = useQuery(["course", gender], query(`${API_URL}/course/${gender}`));
    return data;
}
export function useLiveTiming(gender) {
    const { data } = useQuery(["live-timing", gender], query(`${API_URL}/live-timing/${gender}`), { enabled: !!gender, staleTime: 200, refetchInterval: 200 });
    return data;
}
export function useWeather() {
    const { data } = useQuery(["weather"], query(`${API_URL}/weather`), { staleTime: 1000, refetchInterval: 1000 });
    return data;
}
export function useResults(gender, section) {
    const { data } = useQuery(["leaderboard", gender, section], query(`${API_URL}/leaderboard/${gender}/${section}`), { enabled: !!gender, staleTime: 10000, refetchInterval: 10000 });
    return data;
}
export function query(url, init = undefined) {
    return () => __awaiter(this, void 0, void 0, function* () {
        let response = yield fetch(url, init);
        if (!response.ok) {
            throw new Error("Network request not successful");
        }
        return yield response.json();
    });
}
