export var RunnerClass;
(function (RunnerClass) {
    RunnerClass[RunnerClass["Men"] = 0] = "Men";
    RunnerClass[RunnerClass["Women"] = 1] = "Women";
    RunnerClass[RunnerClass["MU19"] = 2] = "MU19";
    RunnerClass[RunnerClass["WU19"] = 3] = "WU19";
    RunnerClass[RunnerClass["MU23"] = 4] = "MU23";
    RunnerClass[RunnerClass["MU23Crit"] = 5] = "MU23Crit";
    RunnerClass[RunnerClass["WomenCrit"] = 6] = "WomenCrit";
    RunnerClass[RunnerClass["MenCrit"] = 7] = "MenCrit";
})(RunnerClass || (RunnerClass = {}));
export var SuperType;
(function (SuperType) {
    SuperType["Athlete"] = "athlete";
    SuperType["LowerThird"] = "lowerThird";
    SuperType["Ticker"] = "ticker";
    SuperType["GroupIdentifier"] = "groupIdentifier";
    SuperType["CustomLeaderboard"] = "customLeaderboard";
    SuperType["PictureInPicture"] = "pictureInPicture";
})(SuperType || (SuperType = {}));
