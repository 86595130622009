var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import formatTime from "../formatTime";
import { RunnerClass, SuperType } from "../types";
import cn from "classnames";
import { useNavigate, useParams } from "react-router";
import { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import RunnersTable from "../RunnersTable";
import { ComponentButton, HideAllButton, PaginatedComponentButton, } from "./ComponentButton";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditableTable from "./EditableTable";
import { API_URL, runnerClassToGender, useLiveTiming, useResults, useRunnersList, useSupers, useTruck, } from "../queries";
import Map from "../Map";
import Checkbox from "../Checkbox";
import { useMutation, useQueryClient } from "react-query";
import headerCss from "../header.module.css";
import css from "./index.module.css";
import Leaderboard from "../Leaderboard";
var DashboardPanel;
(function (DashboardPanel) {
    DashboardPanel[DashboardPanel["Map"] = 0] = "Map";
    DashboardPanel[DashboardPanel["Splits"] = 1] = "Splits";
    DashboardPanel[DashboardPanel["Supers"] = 2] = "Supers";
})(DashboardPanel || (DashboardPanel = {}));
function panelToName(d) {
    switch (d) {
        case DashboardPanel.Map:
            return "Map";
        case DashboardPanel.Splits:
            return "Official Leaderboard";
        case DashboardPanel.Supers:
            return "Supers";
    }
}
export default function Graphics() {
    var _a, _b, _c, _d;
    const params = useParams();
    const { truckId, gender } = params;
    if (!gender) {
        return null;
    }
    const liveTiming = useLiveTiming(gender);
    const time = (_a = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.raceTime) !== null && _a !== void 0 ? _a : 0;
    const laps = `${(_b = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.currentLap) !== null && _b !== void 0 ? _b : "?"}/${(_c = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.numLaps) !== null && _c !== void 0 ? _c : "?"}`;
    const leaderDistance = (_d = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.leaderDistanceRemaining) !== null && _d !== void 0 ? _d : 0;
    const chase = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.chaseGap;
    const peloton = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.mainGroupGap;
    const navigate = useNavigate();
    const toHome = useCallback(() => navigate("/"), [navigate]);
    const [truckIdTextboxEnabled, setTruckIdTextboxEnabled] = useState(false);
    const onTruckIdChange = useCallback((newTruckId) => {
        setTruckIdTextboxEnabled(false);
        navigate(`/graphics/${gender}/${newTruckId}`);
    }, [navigate]);
    const truck = useTruck(truckId);
    const [openPanel, setOpenPanel] = useState(DashboardPanel.Splits);
    // TODO: Would be good to save the currently selected dashboard for refresh
    // const onPanelChange = useCallback(
    //     (newPanel: DashboardPanel) => {
    //         if (!truckId) return;
    //         setOpenPanel(newPanel);
    //         navigate(`/graphics/${truckId}/${newPanel}`);
    //     },
    //     [truckId, navigate],
    // );
    const [superType, setSuperType] = useState();
    const [superKeys, setSuperKeys] = useState();
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: css.truckSelectorContainer }, { children: [truckId && !truckIdTextboxEnabled ? (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: css.truckIdTag }, { children: ["Connected to ", _jsx("b", { children: truckId }), _jsx(HideAllButton, { truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion })] })) })) : (_jsx("div", { children: _jsx("input", { className: css.truckIdTextbox, type: "text", placeholder: "Truck ID", onKeyDown: (ev) => {
                                if (ev.key === "Enter") {
                                    ev.preventDefault();
                                    onTruckIdChange(ev.currentTarget.value);
                                }
                            } }) })), _jsx("div", Object.assign({ className: cn(css.truckSelector, {
                            [css.enabled]: !!truckId && !truckIdTextboxEnabled,
                        }), onClick: (ev) => {
                            setTruckIdTextboxEnabled(true);
                        } }, { children: _jsx(FontAwesomeIcon, { icon: faTruck }) }))] })), _jsxs("div", Object.assign({ className: css.header }, { children: [_jsx("h1", { children: _jsxs("div", Object.assign({ className: headerCss.title, onClick: toHome }, { children: ["Graphics Operation Dashboard (", gender, ")"] })) }), _jsxs("h2", { children: ["Elapsed Time: ", formatTime(time)] }), _jsx("table", { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: "Leader Distance" }), _jsxs("td", { children: [leaderDistance, "km"] })] }), _jsxs("tr", { children: [_jsx("td", { children: "Lap" }), _jsx("td", { children: laps })] }), _jsxs("tr", { children: [_jsx("td", { children: "Chase Gap" }), _jsx("td", { children: chase ? formatTime(chase) : _jsx(_Fragment, { children: "\u2014" }) })] }), _jsxs("tr", { children: [_jsx("td", { children: "Peloton Gap" }), _jsx("td", { children: peloton ? formatTime(peloton) : _jsx(_Fragment, { children: "\u2014" }) })] })] }) })] })), _jsxs("div", Object.assign({ className: css.content }, { children: [_jsxs("div", Object.assign({ className: cn(css.panel, css.leftInfoPanel) }, { children: [_jsx("div", Object.assign({ className: css.tabList }, { children: [DashboardPanel.Splits, DashboardPanel.Map].map((d) => (_jsx("div", Object.assign({ className: cn(css.tab, {
                                        [css.selected]: openPanel === d,
                                    }), onClick: () => setOpenPanel(d) }, { children: panelToName(d) }), `tab-${d}`))) })), _jsx("div", Object.assign({ className: cn(css.panel, {
                                    [css.panelHidden]: openPanel !== DashboardPanel.Map,
                                }) }, { children: _jsx(MapTab, { runnerClass: genderToRunnerClass(gender) }) })), _jsx("div", Object.assign({ className: cn(css.panel, {
                                    [css.panelHidden]: openPanel !== DashboardPanel.Splits,
                                }) }, { children: _jsx(SplitsTab, {}) }))] })), _jsx("div", Object.assign({ className: cn(css.panel, css.middleSuperPanel) }, { children: _jsx(SupersTab, { superType: superType, setSuperType: setSuperType, superKeys: superKeys, setSuperKeys: setSuperKeys, truckId: truckId }, `supers-panel-${superType}`) })), _jsx("div", Object.assign({ className: cn(css.panel, css.buttons) }, { children: _jsx(Buttons, { truck: truck }) }))] }))] }));
}
function Buttons(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60;
    const { truck } = props;
    const params = useParams();
    const { gender } = params;
    if (!gender) {
        return null;
    }
    const [leadersLabel, setLeadersLabel] = useState("Leaders");
    const [resultsTop3Only, setResultsTop3Only] = useState(((_a = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _a === void 0 ? void 0 : _a.show) === 3 || ((_b = truck === null || truck === void 0 ? void 0 : truck.state.startList) === null || _b === void 0 ? void 0 : _b.show) === 3);
    const [breakawayTitle, setBreakawayTitle] = useState("Leaders");
    const [breakawayRiders, setBreakawayRiders] = useState("");
    const breakawayRiderNumbers = breakawayRiders
        .split(" ")
        .map((n) => Number.parseInt(n))
        .filter((n) => n >= 0);
    useEffect(() => {
        var _a, _b;
        setResultsTop3Only(((_a = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _a === void 0 ? void 0 : _a.show) === 3 ||
            ((_b = truck === null || truck === void 0 ? void 0 : truck.state.startList) === null || _b === void 0 ? void 0 : _b.show) === 3);
    }, [truck === null || truck === void 0 ? void 0 : truck.state.results, truck === null || truck === void 0 ? void 0 : truck.state.startList]);
    const startList = useRunnersList(gender).filter((r) => r.isElite);
    const overallResultList = (_d = (_c = useResults(gender, "overall")) === null || _c === void 0 ? void 0 : _c.runners) !== null && _d !== void 0 ? _d : [];
    const climbResultList = (_f = (_e = useResults(gender, "climb")) === null || _e === void 0 ? void 0 : _e.runners) !== null && _f !== void 0 ? _f : [];
    const sprintResultList = (_h = (_g = useResults(gender, "sprint")) === null || _g === void 0 ? void 0 : _g.runners) !== null && _h !== void 0 ? _h : [];
    const climbLatestList = (_k = (_j = useResults(gender, "climb-latest")) === null || _j === void 0 ? void 0 : _j.runners) !== null && _k !== void 0 ? _k : [];
    const sprintLatestList = (_m = (_l = useResults(gender, "sprint-latest")) === null || _l === void 0 ? void 0 : _l.runners) !== null && _m !== void 0 ? _m : [];
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: css.buttonRow }, { children: [_jsx("input", { type: "text", className: css.truckIdTextbox, value: leadersLabel, onChange: (ev) => {
                            setLeadersLabel(ev.target.value);
                        } }), _jsx(ComponentButton, { title: "Update Label", id: "clockBug", config: {
                            gender,
                            leaderState: (_p = (_o = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _o === void 0 ? void 0 : _o.leaderState) !== null && _p !== void 0 ? _p : null,
                            leaderText: leadersLabel,
                            showChase: (_r = (_q = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _q === void 0 ? void 0 : _q.showChase) !== null && _r !== void 0 ? _r : false,
                            showPeloton: (_t = (_s = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _s === void 0 ? void 0 : _s.showPeloton) !== null && _t !== void 0 ? _t : false,
                            showClock: (_v = (_u = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _u === void 0 ? void 0 : _u.showClock) !== null && _v !== void 0 ? _v : false,
                        }, active: false, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion })] })), _jsxs("div", Object.assign({ className: css.buttonRow }, { children: [_jsx(ComponentButton, { title: "Leader (Distance)", id: "clockBug", config: {
                            gender,
                            leaderState: "distance",
                            leaderText: leadersLabel,
                            showChase: (_x = (_w = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _w === void 0 ? void 0 : _w.showChase) !== null && _x !== void 0 ? _x : false,
                            showPeloton: (_z = (_y = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _y === void 0 ? void 0 : _y.showPeloton) !== null && _z !== void 0 ? _z : false,
                            showClock: (_1 = (_0 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _0 === void 0 ? void 0 : _0.showClock) !== null && _1 !== void 0 ? _1 : false,
                        }, active: !!(truck === null || truck === void 0 ? void 0 : truck.state.clockBug) &&
                            ((_2 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _2 === void 0 ? void 0 : _2.leaderState) === "distance", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, customHideConfig: (current) => {
                            var _a;
                            return (Object.assign(Object.assign({}, current), { leaderState: ((_a = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _a === void 0 ? void 0 : _a.leaderState) === "laps"
                                    ? "distance"
                                    : null }));
                        } }), _jsx(ComponentButton, { title: "Leader (Laps)", id: "clockBug", config: {
                            gender,
                            leaderState: "laps",
                            leaderText: leadersLabel,
                            showChase: (_4 = (_3 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _3 === void 0 ? void 0 : _3.showChase) !== null && _4 !== void 0 ? _4 : false,
                            showPeloton: (_6 = (_5 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _5 === void 0 ? void 0 : _5.showPeloton) !== null && _6 !== void 0 ? _6 : false,
                            showClock: (_8 = (_7 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _7 === void 0 ? void 0 : _7.showClock) !== null && _8 !== void 0 ? _8 : false,
                        }, active: !!(truck === null || truck === void 0 ? void 0 : truck.state.clockBug) &&
                            ((_9 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _9 === void 0 ? void 0 : _9.leaderState) === "laps", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, customHideConfig: (current) => {
                            var _a;
                            return (Object.assign(Object.assign({}, current), { leaderState: ((_a = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _a === void 0 ? void 0 : _a.leaderState) === "distance"
                                    ? "laps"
                                    : null }));
                        } })] })), _jsx(ComponentButton, { title: "Chase", id: "clockBug", disabled: !((_10 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _10 === void 0 ? void 0 : _10.leaderState), config: {
                    gender,
                    leaderState: (_12 = (_11 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _11 === void 0 ? void 0 : _11.leaderState) !== null && _12 !== void 0 ? _12 : "distance",
                    leaderText: leadersLabel,
                    showChase: true,
                    showPeloton: (_14 = (_13 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _13 === void 0 ? void 0 : _13.showPeloton) !== null && _14 !== void 0 ? _14 : false,
                    showClock: (_16 = (_15 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _15 === void 0 ? void 0 : _15.showClock) !== null && _16 !== void 0 ? _16 : false,
                }, active: !!((_17 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _17 === void 0 ? void 0 : _17.showChase), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, customHideConfig: (current) => (Object.assign(Object.assign({}, current), { showChase: false })) }), _jsx(ComponentButton, { title: "Peloton", id: "clockBug", disabled: !((_18 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _18 === void 0 ? void 0 : _18.leaderState), config: {
                    gender,
                    leaderState: (_20 = (_19 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _19 === void 0 ? void 0 : _19.leaderState) !== null && _20 !== void 0 ? _20 : "distance",
                    leaderText: leadersLabel,
                    showChase: (_22 = (_21 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _21 === void 0 ? void 0 : _21.showChase) !== null && _22 !== void 0 ? _22 : false,
                    showPeloton: true,
                    showClock: (_24 = (_23 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _23 === void 0 ? void 0 : _23.showClock) !== null && _24 !== void 0 ? _24 : false,
                }, active: !!((_25 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _25 === void 0 ? void 0 : _25.showPeloton), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, customHideConfig: (current) => (Object.assign(Object.assign({}, current), { showPeloton: false })) }), _jsx(ComponentButton, { title: "Clock", id: "clockBug", config: {
                    gender,
                    leaderState: (_27 = (_26 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _26 === void 0 ? void 0 : _26.leaderState) !== null && _27 !== void 0 ? _27 : null,
                    leaderText: leadersLabel,
                    showChase: (_29 = (_28 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _28 === void 0 ? void 0 : _28.showChase) !== null && _29 !== void 0 ? _29 : false,
                    showPeloton: (_31 = (_30 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _30 === void 0 ? void 0 : _30.showPeloton) !== null && _31 !== void 0 ? _31 : false,
                    showClock: true,
                }, active: !!((_32 = truck === null || truck === void 0 ? void 0 : truck.state.clockBug) === null || _32 === void 0 ? void 0 : _32.showClock), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, customHideConfig: (current) => (Object.assign(Object.assign({}, current), { showClock: false })) }), _jsx("hr", {}), _jsx("input", { type: "text", className: css.truckIdTextbox, placeholder: "Breakaway Title", value: breakawayTitle, disabled: !!((_33 = truck === null || truck === void 0 ? void 0 : truck.state.leaders) === null || _33 === void 0 ? void 0 : _33.title), onChange: (ev) => setBreakawayTitle(ev.currentTarget.value) }), _jsx("input", { type: "text", className: css.truckIdTextbox, placeholder: "Rider Numbers (e.g. 2 18 1)", value: breakawayRiders, disabled: !!(truck === null || truck === void 0 ? void 0 : truck.state.leaders), onChange: (ev) => setBreakawayRiders(ev.currentTarget.value) }), _jsx(PaginatedComponentButton, { title: "Breakaway Riders", id: "leaders", disabled: (!breakawayTitle || breakawayRiderNumbers.length == 0) &&
                    !((_34 = truck === null || truck === void 0 ? void 0 : truck.state.leaders) === null || _34 === void 0 ? void 0 : _34.gender), config: {
                    gender,
                    page: (_36 = (_35 = truck === null || truck === void 0 ? void 0 : truck.state.leaders) === null || _35 === void 0 ? void 0 : _35.page) !== null && _36 !== void 0 ? _36 : 1,
                    title: breakawayTitle,
                    riders: breakawayRiderNumbers,
                }, active: !!((_37 = truck === null || truck === void 0 ? void 0 : truck.state.leaders) === null || _37 === void 0 ? void 0 : _37.gender), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, pages: Math.floor((breakawayRiderNumbers.length - 1) / 5) + 1 }), _jsx("hr", {}), _jsx("div", { children: _jsx(Checkbox, { title: "Show top 3 only", checked: resultsTop3Only, onChange: function (checked) {
                        if ((truck === null || truck === void 0 ? void 0 : truck.state.results) || (truck === null || truck === void 0 ? void 0 : truck.state.startList)) {
                            // Cannot change when the component is active
                            return;
                        }
                        setResultsTop3Only(checked);
                    } }) }), _jsx(PaginatedComponentButton, { title: "FF Top Contenders", id: "startList", config: {
                    gender,
                    page: (_39 = (_38 = truck === null || truck === void 0 ? void 0 : truck.state.startList) === null || _38 === void 0 ? void 0 : _38.page) !== null && _39 !== void 0 ? _39 : 1,
                    show: resultsTop3Only ? 3 : 10,
                }, active: !!((_40 = truck === null || truck === void 0 ? void 0 : truck.state.startList) === null || _40 === void 0 ? void 0 : _40.page) &&
                    (truck === null || truck === void 0 ? void 0 : truck.state.startList.gender) == gender, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: startList.length == 0, pages: resultsTop3Only
                    ? 1
                    : Math.floor((startList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "FF Results", id: "results", config: {
                    gender,
                    section: "overall",
                    page: (_42 = (_41 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _41 === void 0 ? void 0 : _41.page) !== null && _42 !== void 0 ? _42 : 1,
                    show: resultsTop3Only ? 3 : 10,
                }, active: ((_43 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _43 === void 0 ? void 0 : _43.gender) === gender &&
                    ((_44 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _44 === void 0 ? void 0 : _44.section) === "overall", disabled: overallResultList.length == 0, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, pages: resultsTop3Only
                    ? 1
                    : Math.floor((overallResultList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "FF Climb Total", id: "results", config: {
                    gender,
                    section: "climb",
                    page: (_46 = (_45 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _45 === void 0 ? void 0 : _45.page) !== null && _46 !== void 0 ? _46 : 1,
                    show: resultsTop3Only ? 3 : 10,
                }, active: ((_47 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _47 === void 0 ? void 0 : _47.gender) === gender &&
                    ((_48 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _48 === void 0 ? void 0 : _48.section) === "climb", disabled: climbResultList.length == 0, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, pages: resultsTop3Only
                    ? 1
                    : Math.floor((climbResultList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "FF Sprint Total", id: "results", config: {
                    gender,
                    section: "sprint",
                    page: (_50 = (_49 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _49 === void 0 ? void 0 : _49.page) !== null && _50 !== void 0 ? _50 : 1,
                    show: resultsTop3Only ? 3 : 10,
                }, active: ((_51 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _51 === void 0 ? void 0 : _51.gender) === gender &&
                    ((_52 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _52 === void 0 ? void 0 : _52.section) === "sprint", disabled: sprintResultList.length == 0, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, pages: resultsTop3Only
                    ? 1
                    : Math.floor((sprintResultList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "FF Climb Latest", id: "results", config: {
                    gender,
                    section: "climb-latest",
                    page: (_54 = (_53 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _53 === void 0 ? void 0 : _53.page) !== null && _54 !== void 0 ? _54 : 1,
                    show: 3,
                }, active: ((_55 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _55 === void 0 ? void 0 : _55.gender) === gender &&
                    ((_56 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _56 === void 0 ? void 0 : _56.section) === "climb-latest", disabled: climbLatestList.length == 0 && !(truck === null || truck === void 0 ? void 0 : truck.state.results), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, pages: 1 }), _jsx(PaginatedComponentButton, { title: "FF Sprint Latest", id: "results", config: {
                    gender,
                    section: "sprint-latest",
                    page: (_58 = (_57 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _57 === void 0 ? void 0 : _57.page) !== null && _58 !== void 0 ? _58 : 1,
                    show: 3,
                }, active: ((_59 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _59 === void 0 ? void 0 : _59.gender) === gender &&
                    ((_60 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _60 === void 0 ? void 0 : _60.section) === "sprint-latest", disabled: sprintLatestList.length == 0 && !(truck === null || truck === void 0 ? void 0 : truck.state.results), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, pages: 1 })] }));
}
function AthletesTab(props) {
    const { selectedRunner, setSelectedRunner } = props;
    const params = useParams();
    const { gender } = params;
    if (!gender) {
        return null;
    }
    const runners = useRunnersList(gender);
    return (_jsxs("div", Object.assign({ className: css.athletesTab }, { children: [_jsx(RunnersTable, { runners: runners, onRunnerSelected: (id) => setSelectedRunner(id), currentRunner: selectedRunner }), _jsx("div", Object.assign({ className: cn(css.setNoAthleteButton, {
                    [css.selected]: selectedRunner === undefined,
                }), onClick: () => setSelectedRunner(undefined) }, { children: "No Athlete selected" }))] })));
}
function MapTab(props) {
    var _a, _b, _c;
    const liveTiming = useLiveTiming(runnerClassToGender(props.runnerClass));
    return (_jsx(_Fragment, { children: _jsx(Map, { gender: runnerClassToGender(props.runnerClass), leaderDistance: (_a = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.leaderDistanceRemaining) !== null && _a !== void 0 ? _a : 0, leaderPosition: (_b = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.leaderPosition) !== null && _b !== void 0 ? _b : null, chasePosition: (_c = liveTiming === null || liveTiming === void 0 ? void 0 : liveTiming.chasePosition) !== null && _c !== void 0 ? _c : null, width: "500px" }) }));
}
function SplitsTab() {
    const params = useParams();
    const { gender } = params;
    if (!gender) {
        return null;
    }
    return _jsx(Leaderboard, { group: genderToRunnerClass(gender) });
}
function resultsSectionToDetails(gender, s) {
    switch (s) {
        case "topContenders":
            switch (gender) {
                case "men":
                    return "Elite Men's Top Contenders";
                case "women":
                    return "U23 & Elite Women's Top Contenders";
                case "mj":
                    return "U19 Men's Top Contenders";
                case "wj":
                    return "U19 Women's Top Contenders";
                case "mu":
                    return "U23 Men's Top Contenders";
                case "mu-crit":
                    return "U23 Men's Criterium Top Contenders";
                case "men-crit":
                    return "Elite Men's Criterium Top Contenders";
                case "women-crit":
                    return "U23 & Elite Women's Criterium Top Contenders";
                default:
                    return "Top Contenders";
            }
        case "overall":
            switch (gender) {
                case "men":
                    return "Elite Men's Results";
                case "women":
                    return "U23 & Elite Women's Results";
                case "mj":
                    return "U19 Men's Results";
                case "wj":
                    return "U19 Women's Results";
                case "mu":
                    return "U23 Men's Results";
                case "mu-crit":
                    return "U23 Men's Criterium Results";
                case "men-crit":
                    return "Elite Men's Criterium Results";
                case "women-crit":
                    return "U23 & Elite Women's Criterium Results";
                default:
                    return "Results";
            }
        case "climb":
        case "climb-latest":
            return gender === "men" ||
                gender == "mu" ||
                gender == "mj" ||
                gender == "men-crit" ||
                gender == "mu-crit"
                ? "King of the Mountain"
                : "Queen of the Mountain";
        case "sprint":
        case "sprint-latest":
            return "Intermediate Sprint";
    }
    return "";
}
function CustomLeaderboardTab(props) {
    const { showOnlyTop3, setShowOnlyTop3, section, setSection, heading, rungs, setRungs, disabled, } = props;
    const params = useParams();
    const { gender } = params;
    if (!gender) {
        return null;
    }
    const runners = useRunnersList(gender);
    const addOrUpdateRow = useCallback((i, newRow) => {
        const newValues = rungs.slice();
        const newRow2 = Object.assign(Object.assign({}, newRow), { result: newRow.result.toUpperCase() });
        if (i == -1) {
            newValues.push(newRow2);
        }
        else {
            newValues[i] = newRow2;
        }
        setRungs(newValues);
    }, [setRungs]);
    const deleteRow = useCallback((i) => {
        const newValues = rungs.slice();
        newValues.splice(i, 1);
        setRungs(newValues);
    }, [setRungs]);
    return (_jsxs("div", Object.assign({ className: css.customLeaderboard }, { children: [_jsx("div", { children: _jsx(Checkbox, { title: "Show top 3 only", checked: showOnlyTop3, onChange: function (checked) {
                        if (disabled) {
                            // Cannot change when the component is active
                            return;
                        }
                        setShowOnlyTop3(checked);
                    } }) }), _jsx("hr", {}), _jsx("div", { children: [
                    "topContenders",
                    "overall",
                    "climb",
                    "sprint",
                ].map((s) => (_jsx(Checkbox, { title: resultsSectionToDetails(gender, s), checked: section === s, onChange: function (checked) {
                        if (disabled) {
                            // Cannot change when the component is active
                            return;
                        }
                        setSection(s);
                    } }, s))) }), _jsx("hr", {}), _jsx(EditableTable, { superType: SuperType.LowerThird, manualSuper: useMemo(() => ({
                    headers: section === "topContenders"
                        ? [
                            { key: "number", name: "Runner number" },
                            {
                                key: "result",
                                name: "Home State",
                            },
                        ]
                        : [
                            { key: "number", name: "Runner number" },
                            { key: "position", name: "Position" },
                            {
                                key: "result",
                                name: "Result",
                            },
                        ],
                    values: rungs,
                }), [rungs, section]), addOrUpdateRow: addOrUpdateRow, deleteRow: deleteRow, shortKeys: ["number", "position"] }), _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), _jsx("hr", {}), _jsx("h3", { children: heading }), _jsx("table", Object.assign({ className: css.customLeaderboardTable }, { children: _jsx("tbody", { children: rungs.slice(0, showOnlyTop3 ? 3 : 10).map((r) => {
                        let runner = runners.find((rr) => rr.bibNumber.toString() === r.number);
                        if (!runner) {
                            return (_jsxs("tr", { children: [_jsx("td", { children: "!!" }), _jsx("td", { children: "UNKNOWN RUNNER" }), _jsx("td", { children: "!!" })] }));
                        }
                        let name = `${runner.firstName} ${runner.lastName.toUpperCase()}`;
                        if (runner.isU23) {
                            name += "*";
                        }
                        if (runner.teamName) {
                            name += ` (${runner.teamName.toUpperCase()})`;
                        }
                        if (section === "topContenders") {
                            return (_jsxs("tr", { children: [_jsxs("td", { children: [r.number, "."] }), _jsx("td", { children: name }), _jsx("td", { children: r.result })] }));
                        }
                        else {
                            return (_jsxs("tr", { children: [_jsx("td", { children: r.position }), _jsx("td", { children: name }), _jsx("td", { children: r.result })] }));
                        }
                    }) }) }))] })));
}
function superTypeToName(s) {
    switch (s) {
        case SuperType.Athlete:
            return "Athlete";
        case SuperType.LowerThird:
            return "Lower Third";
        case SuperType.GroupIdentifier:
            return "Group Identifier";
        case SuperType.Ticker:
            return "Ticker";
        case SuperType.PictureInPicture:
            return "Picture-in-picture";
        case SuperType.CustomLeaderboard:
            return "Leaderboard";
    }
}
function SupersTab(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37;
    const { superType, setSuperType, superKeys, setSuperKeys } = props;
    const params = useParams();
    const { gender } = params;
    if (!gender) {
        return null;
    }
    const truck = useTruck(props.truckId);
    const queryClient = useQueryClient();
    const runners = useRunnersList(gender);
    const [selectedRunner, setSelectedRunner] = useState();
    const prevSuperType = useRef();
    const CUSTOM_LEADERBOARD_STORE_KEY = `customLeaderboard+${gender}`;
    const CUSTOM_LEADERBOARD_VERSION = "0";
    const [showOnlyTop3, setStateShowOnlyTop3] = useState(true);
    const [section, setStateSection] = useState("overall");
    const leaderboardHeading = resultsSectionToDetails(gender, section);
    const [rungs, setStateRungs] = useState([]);
    const mjOverallList = (_b = (_a = useResults("mj", "overall")) === null || _a === void 0 ? void 0 : _a.runners) !== null && _b !== void 0 ? _b : [];
    const wjOverallList = (_d = (_c = useResults("wj", "overall")) === null || _c === void 0 ? void 0 : _c.runners) !== null && _d !== void 0 ? _d : [];
    const muOverallList = (_f = (_e = useResults("mu", "overall")) === null || _e === void 0 ? void 0 : _e.runners) !== null && _f !== void 0 ? _f : [];
    const mjClimbList = (_h = (_g = useResults("mj", "climb")) === null || _g === void 0 ? void 0 : _g.runners) !== null && _h !== void 0 ? _h : [];
    const wjClimbList = (_k = (_j = useResults("wj", "climb")) === null || _j === void 0 ? void 0 : _j.runners) !== null && _k !== void 0 ? _k : [];
    const muClimbList = (_m = (_l = useResults("mu", "climb")) === null || _l === void 0 ? void 0 : _l.runners) !== null && _m !== void 0 ? _m : [];
    const mjSprintList = (_p = (_o = useResults("mj", "sprint")) === null || _o === void 0 ? void 0 : _o.runners) !== null && _p !== void 0 ? _p : [];
    const wjSprintList = (_r = (_q = useResults("wj", "sprint")) === null || _q === void 0 ? void 0 : _q.runners) !== null && _r !== void 0 ? _r : [];
    const muSprintList = (_t = (_s = useResults("mu", "sprint")) === null || _s === void 0 ? void 0 : _s.runners) !== null && _t !== void 0 ? _t : [];
    useEffect(() => {
        // On section change, load the custom leaderboard from state
        if (prevSuperType.current === props.superType) {
            return;
        }
        prevSuperType.current = props.superType;
        const customLeaderboardRaw = window.localStorage.getItem(CUSTOM_LEADERBOARD_STORE_KEY);
        if (!customLeaderboardRaw) {
            return;
        }
        const customLeaderboard = JSON.parse(customLeaderboardRaw);
        if (customLeaderboard.version !== CUSTOM_LEADERBOARD_VERSION) {
            // Bad version... Clear
            window.localStorage.removeItem(CUSTOM_LEADERBOARD_STORE_KEY);
        }
        setStateShowOnlyTop3(customLeaderboard.showOnlyTop3);
        setStateSection(customLeaderboard.section);
        setStateRungs(customLeaderboard.rungs);
    }, [props.superType]);
    const writeCustomLeaderboardStorage = () => {
        window.localStorage.setItem(CUSTOM_LEADERBOARD_STORE_KEY, JSON.stringify({
            rungs,
            showOnlyTop3,
            section,
            version: CUSTOM_LEADERBOARD_VERSION,
        }));
    };
    const setShowOnlyTop3 = (b) => {
        setStateShowOnlyTop3(b);
        writeCustomLeaderboardStorage();
    };
    const setRungs = (r) => {
        setStateRungs(r);
        writeCustomLeaderboardStorage();
    };
    const setSection = (s) => {
        setStateSection(s);
        writeCustomLeaderboardStorage();
    };
    const allSupers = useSupers();
    const supers = useMemo(() => {
        if (superType == undefined) {
            return undefined;
        }
        const headers = superType === "lowerThird"
            ? [
                { key: "description", name: "Bottom line" },
                { key: "firstName", name: "Top Line" },
                { key: "logoId", name: "Sponsor" },
            ]
            : superType === "groupIdentifier"
                ? [{ key: "text", name: "Group Name" }]
                : superType === "ticker"
                    ? [
                        { key: "text", name: "Ticker Text" },
                        { key: "logoId", name: "Logo" },
                    ]
                    : superType === "pictureInPicture"
                        ? [
                            { key: "left", name: "Left" },
                            { key: "right", name: "Right" },
                        ]
                        : [];
        const values = allSupers
            .slice()
            .filter((s) => s.data.kind == superType)
            .map((s) => {
            const o2 = Object.assign({}, s.data);
            delete o2.kind;
            Object.keys(o2).forEach((k) => {
                if (!o2[k]) {
                    o2[k] = undefined;
                }
            });
            return [s.id, o2];
        });
        return { headers, values };
    }, [superType, allSupers]);
    const addRowMutation = useMutation((s) => __awaiter(this, void 0, void 0, function* () {
        const superData = validateSuper(superType, s);
        if (!superData) {
            throw new Error("Invalid super");
        }
        yield fetch(`${API_URL}/supers`, {
            body: JSON.stringify(superData),
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    }), {
        onMutate: (s) => __awaiter(this, void 0, void 0, function* () {
            const superData = validateSuper(superType, s);
            if (!superData) {
                return;
            }
            yield queryClient.cancelQueries({ queryKey: ["supers"] });
            const snapshot = allSupers.slice();
            queryClient.setQueryData(["supers"], () => [
                ...allSupers,
                { id: "new-super", data: superData },
            ]);
            return { snapshot };
        }),
        onError: (err, s, context) => {
            if (!context) {
                return;
            }
            queryClient.setQueryData(["supers"], context.snapshot);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["supers"] });
        },
    });
    const updateRowMutation = useMutation((data) => __awaiter(this, void 0, void 0, function* () {
        var _38;
        const oldId = (_38 = supers === null || supers === void 0 ? void 0 : supers.values[data.index]) === null || _38 === void 0 ? void 0 : _38[0];
        if (!oldId) {
            throw new Error("Bad update??");
        }
        const superData = validateSuper(superType, data.config);
        if (!superData) {
            throw new Error("Invalid super");
        }
        yield fetch(`${API_URL}/supers/${oldId}`, {
            body: JSON.stringify(superData),
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        });
    }), {
        onMutate: (data) => __awaiter(this, void 0, void 0, function* () {
            var _39;
            if (!supers) {
                return;
            }
            const oldId = (_39 = supers.values[data.index]) === null || _39 === void 0 ? void 0 : _39[0];
            const oldSuperIndex = allSupers.findIndex((s) => s.id === oldId);
            if (oldSuperIndex === -1) {
                return;
            }
            const superData = validateSuper(superType, data.config);
            if (!superData) {
                return;
            }
            yield queryClient.cancelQueries({ queryKey: ["supers"] });
            const snapshot = allSupers.slice();
            queryClient.setQueryData(["supers"], () => [
                ...allSupers.slice(0, oldSuperIndex),
                { id: oldId, data: superData },
                ...allSupers.slice(oldSuperIndex + 1),
            ]);
            return { snapshot };
        }),
        onError: (err, s, context) => {
            if (!context) {
                return;
            }
            queryClient.setQueryData(["supers"], context.snapshot);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["supers"] });
        },
    });
    const deleteRowMutation = useMutation((index) => __awaiter(this, void 0, void 0, function* () {
        var _40;
        const oldId = (_40 = supers === null || supers === void 0 ? void 0 : supers.values[index]) === null || _40 === void 0 ? void 0 : _40[0];
        if (!oldId) {
            throw new Error("Bad update??");
        }
        yield fetch(`${API_URL}/supers/${oldId}`, {
            method: "DELETE",
        });
    }), {
        onMutate: (index) => __awaiter(this, void 0, void 0, function* () {
            var _41;
            if (!supers) {
                return;
            }
            const oldId = (_41 = supers.values[index]) === null || _41 === void 0 ? void 0 : _41[0];
            const oldSuperIndex = allSupers.findIndex((s) => s.id === oldId);
            if (oldSuperIndex === -1) {
                return;
            }
            yield queryClient.cancelQueries({ queryKey: ["supers"] });
            const snapshot = allSupers.slice();
            queryClient.setQueryData(["supers"], () => [
                ...allSupers.slice(0, oldSuperIndex),
                ...allSupers.slice(oldSuperIndex + 1),
            ]);
            return { snapshot };
        }),
        onError: (err, s, context) => {
            if (!context) {
                return;
            }
            queryClient.setQueryData(["supers"], context.snapshot);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["supers"] });
        },
    });
    return (_jsxs("div", Object.assign({ className: css.supersTab }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: css.supersTabList }, { children: [
                            SuperType.Athlete,
                            SuperType.GroupIdentifier,
                            SuperType.LowerThird,
                            SuperType.Ticker,
                            SuperType.PictureInPicture,
                            SuperType.CustomLeaderboard,
                        ].map((s) => (_jsx("div", Object.assign({ className: cn(css.tab, {
                                [css.selected]: superType === s,
                            }), onClick: () => {
                                if (superType !== s) {
                                    // Reset keys if changing super
                                    setSelectedRunner(undefined);
                                    setSuperKeys(undefined);
                                }
                                setSuperType(s);
                            } }, { children: superTypeToName(s) }), `supers-tab-${s}`))) })), _jsx("div", Object.assign({ className: cn(css.superTable, {
                            [css.panelHidden]: superType !== SuperType.Athlete,
                        }) }, { children: _jsx(AthletesTab, { selectedRunner: selectedRunner, setSelectedRunner: (r) => {
                                var _a, _b;
                                if (r !== undefined) {
                                    const runner = runners.find((rr) => rr.bibNumber === r);
                                    if (!runner) {
                                        setSelectedRunner(undefined);
                                        return;
                                    }
                                    let name = `${runner.bibNumber}. ${runner.firstName} ${runner.lastName.toUpperCase()}`;
                                    if (runner.isU23) {
                                        name += "*";
                                    }
                                    if (runner.state !== null) {
                                        name += ` (${runner.state})`;
                                    }
                                    setSuperKeys({
                                        firstName: name,
                                        description: (_b = (_a = runner.teamName) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== null && _b !== void 0 ? _b : undefined,
                                    });
                                }
                                setSelectedRunner(r);
                            } }) })), _jsx("div", Object.assign({ className: cn(css.superTable, {
                            [css.panelHidden]: superType !== SuperType.CustomLeaderboard,
                        }) }, { children: _jsx(CustomLeaderboardTab, { showOnlyTop3: showOnlyTop3, setShowOnlyTop3: setShowOnlyTop3, section: section, setSection: setSection, heading: leaderboardHeading, rungs: rungs, setRungs: setRungs, disabled: !!(truck === null || truck === void 0 ? void 0 : truck.state.customLeaderboard) }) })), _jsx("div", Object.assign({ className: cn(css.superTable, {
                            [css.panelHidden]: superType === SuperType.Athlete ||
                                superType === SuperType.CustomLeaderboard,
                        }) }, { children: _jsx(EditableTable, { superType: superType, manualSuper: supers
                                ? {
                                    headers: supers.headers,
                                    values: supers.values.map((sup) => sup[1]),
                                }
                                : undefined, addOrUpdateRow: (index, config) => {
                                if (index === -1) {
                                    addRowMutation.mutate(config);
                                }
                                else {
                                    updateRowMutation.mutate({ index, config });
                                }
                            }, deleteRow: (index) => {
                                deleteRowMutation.mutate(index);
                            }, selection: {
                                superKeys: superKeys,
                                setSuperKeys: setSuperKeys,
                            }, logoShowNoneOption: superType === SuperType.LowerThird, longKeys: ["firstName", "text", "description"] }, `editable-table-${superType}`) }))] }), _jsxs("div", Object.assign({ className: css.superButtons }, { children: [_jsx(ComponentButton, { title: "Group Identifier", id: "groupIdentifier", config: {
                            text: (_u = superKeys === null || superKeys === void 0 ? void 0 : superKeys.text) !== null && _u !== void 0 ? _u : "",
                        }, disabled: !(truck === null || truck === void 0 ? void 0 : truck.state.groupIdentifier) &&
                            (!superKeys ||
                                superType !== SuperType.GroupIdentifier ||
                                !superKeys.text), active: !!(truck === null || truck === void 0 ? void 0 : truck.state.groupIdentifier), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion }), _jsx(ComponentButton, { title: "Lower Third", id: "lowerThirdPerson", config: {
                            firstName: (_v = superKeys === null || superKeys === void 0 ? void 0 : superKeys.firstName) !== null && _v !== void 0 ? _v : "",
                            lastName: (_w = superKeys === null || superKeys === void 0 ? void 0 : superKeys.lastName) !== null && _w !== void 0 ? _w : "",
                            description: (superKeys === null || superKeys === void 0 ? void 0 : superKeys.description)
                                ? superKeys.description
                                : null,
                            logo: true,
                            logoId: (_x = superKeys === null || superKeys === void 0 ? void 0 : superKeys.logoId) !== null && _x !== void 0 ? _x : null,
                            descriptionOnBottom: superType === SuperType.Athlete ||
                                superType === SuperType.LowerThird,
                        }, disabled: !(truck === null || truck === void 0 ? void 0 : truck.state.lowerThirdPerson) &&
                            (!superKeys ||
                                (superType !== SuperType.Athlete &&
                                    superType !== SuperType.LowerThird) ||
                                !superKeys.firstName), active: !!(truck === null || truck === void 0 ? void 0 : truck.state.lowerThirdPerson), truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion }), _jsx(ComponentButton, { title: "Ticker", id: "ticker", config: {
                            text: (_y = superKeys === null || superKeys === void 0 ? void 0 : superKeys.text) !== null && _y !== void 0 ? _y : "",
                            logoId: (_z = superKeys === null || superKeys === void 0 ? void 0 : superKeys.logoId) !== null && _z !== void 0 ? _z : null,
                        }, disabled: !(truck === null || truck === void 0 ? void 0 : truck.state.ticker) &&
                            (!superKeys ||
                                superType !== SuperType.Ticker ||
                                !superKeys.text ||
                                !superKeys.logoId), active: (truck === null || truck === void 0 ? void 0 : truck.state.ticker) != null, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion }), _jsx(ComponentButton, { title: "Pic in Pic", id: "pictureInPicture", config: {
                            left: (_0 = superKeys === null || superKeys === void 0 ? void 0 : superKeys.left) !== null && _0 !== void 0 ? _0 : "",
                            right: (_1 = superKeys === null || superKeys === void 0 ? void 0 : superKeys.right) !== null && _1 !== void 0 ? _1 : "",
                        }, disabled: !(truck === null || truck === void 0 ? void 0 : truck.state.pictureInPicture) &&
                            (!superKeys ||
                                superType !== SuperType.PictureInPicture ||
                                !superKeys.left ||
                                !superKeys.right), active: (truck === null || truck === void 0 ? void 0 : truck.state.pictureInPicture) != null, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion }), _jsx("br", {}), _jsx(ComponentButton, { title: "Custom leaderboard", id: "customLeaderboard", config: {
                            gender,
                            section,
                            heading: leaderboardHeading,
                            show: showOnlyTop3 ? 3 : 10,
                            rungs: rungs.map((r) => {
                                let runner = runners.find((rr) => rr.bibNumber.toString() === r.number);
                                if (!runner) {
                                    return {
                                        left: "",
                                        middle: "",
                                        right: "",
                                    };
                                }
                                let name = `${runner.firstName} ${runner.lastName.toUpperCase()}`;
                                if (runner.isU23) {
                                    name += "*";
                                }
                                if (runner.teamName) {
                                    name += ` (${runner.teamName.toUpperCase()})`;
                                }
                                return {
                                    left: section === "topContenders"
                                        ? `${r.number}.`
                                        : r.position,
                                    middle: name,
                                    right: r.result,
                                };
                            }),
                        }, disabled: !(truck === null || truck === void 0 ? void 0 : truck.state.customLeaderboard) &&
                            (superType !== SuperType.CustomLeaderboard ||
                                rungs.length === 0 ||
                                rungs.some((r) => !runners.find((rr) => rr.bibNumber.toString() ===
                                    r.number))), active: (truck === null || truck === void 0 ? void 0 : truck.state.customLeaderboard) != null, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion }), _jsx("br", {}), _jsx(ComponentButton, { title: "Weather", id: "weather", config: {}, active: (truck === null || truck === void 0 ? void 0 : truck.state.weather) != null, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion }), _jsx("br", {}), _jsx("hr", {}), _jsx(PaginatedComponentButton, { title: "MEN U19 Overall", id: "results", config: {
                            gender: "mj",
                            section: "overall",
                            page: (_3 = (_2 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _2 === void 0 ? void 0 : _2.page) !== null && _3 !== void 0 ? _3 : 1,
                            show: 10,
                        }, active: ((_4 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _4 === void 0 ? void 0 : _4.gender) === "mj" &&
                            ((_5 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _5 === void 0 ? void 0 : _5.section) === "overall", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: mjOverallList.length == 0, pages: Math.floor((mjOverallList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "WOMEN U19 Overall", id: "results", config: {
                            gender: "wj",
                            section: "overall",
                            page: (_7 = (_6 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _6 === void 0 ? void 0 : _6.page) !== null && _7 !== void 0 ? _7 : 1,
                            show: 10,
                        }, active: ((_8 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _8 === void 0 ? void 0 : _8.gender) === "wj" &&
                            ((_9 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _9 === void 0 ? void 0 : _9.section) === "overall", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: wjOverallList.length == 0, pages: Math.floor((wjOverallList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "MEN U23 Overall", id: "results", config: {
                            gender: "mu",
                            section: "overall",
                            page: (_11 = (_10 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _10 === void 0 ? void 0 : _10.page) !== null && _11 !== void 0 ? _11 : 1,
                            show: 10,
                        }, active: ((_12 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _12 === void 0 ? void 0 : _12.gender) === "mu" &&
                            ((_13 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _13 === void 0 ? void 0 : _13.section) === "overall", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: muOverallList.length == 0, pages: Math.floor((muOverallList.length - 1) / 10) + 1 }), _jsx("br", {}), _jsx(PaginatedComponentButton, { title: "MEN U19 Sprint", id: "results", config: {
                            gender: "mj",
                            section: "sprint",
                            page: (_15 = (_14 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _14 === void 0 ? void 0 : _14.page) !== null && _15 !== void 0 ? _15 : 1,
                            show: 10,
                        }, active: ((_16 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _16 === void 0 ? void 0 : _16.gender) === "mj" &&
                            ((_17 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _17 === void 0 ? void 0 : _17.section) === "sprint", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: mjSprintList.length == 0, pages: Math.floor((mjSprintList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "WOMEN U19 Sprint", id: "results", config: {
                            gender: "wj",
                            section: "sprint",
                            page: (_19 = (_18 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _18 === void 0 ? void 0 : _18.page) !== null && _19 !== void 0 ? _19 : 1,
                            show: 10,
                        }, active: ((_20 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _20 === void 0 ? void 0 : _20.gender) === "wj" &&
                            ((_21 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _21 === void 0 ? void 0 : _21.section) === "sprint", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: wjSprintList.length == 0, pages: Math.floor((wjSprintList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "MEN U23 Sprint", id: "results", config: {
                            gender: "mu",
                            section: "sprint",
                            page: (_23 = (_22 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _22 === void 0 ? void 0 : _22.page) !== null && _23 !== void 0 ? _23 : 1,
                            show: 10,
                        }, active: ((_24 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _24 === void 0 ? void 0 : _24.gender) === "mu" &&
                            ((_25 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _25 === void 0 ? void 0 : _25.section) === "sprint", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: muSprintList.length == 0, pages: Math.floor((muSprintList.length - 1) / 10) + 1 }), _jsx("br", {}), _jsx(PaginatedComponentButton, { title: "MEN U19 Climb", id: "results", config: {
                            gender: "mj",
                            section: "climb",
                            page: (_27 = (_26 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _26 === void 0 ? void 0 : _26.page) !== null && _27 !== void 0 ? _27 : 1,
                            show: 10,
                        }, active: ((_28 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _28 === void 0 ? void 0 : _28.gender) === "mj" &&
                            ((_29 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _29 === void 0 ? void 0 : _29.section) === "climb", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: mjClimbList.length == 0, pages: Math.floor((mjClimbList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "WOMEN U19 Climb", id: "results", config: {
                            gender: "wj",
                            section: "climb",
                            page: (_31 = (_30 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _30 === void 0 ? void 0 : _30.page) !== null && _31 !== void 0 ? _31 : 1,
                            show: 10,
                        }, active: ((_32 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _32 === void 0 ? void 0 : _32.gender) === "wj" &&
                            ((_33 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _33 === void 0 ? void 0 : _33.section) === "climb", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: wjClimbList.length == 0, pages: Math.floor((wjClimbList.length - 1) / 10) + 1 }), _jsx(PaginatedComponentButton, { title: "MEN U23 Climb", id: "results", config: {
                            gender: "mu",
                            section: "climb",
                            page: (_35 = (_34 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _34 === void 0 ? void 0 : _34.page) !== null && _35 !== void 0 ? _35 : 1,
                            show: 10,
                        }, active: ((_36 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _36 === void 0 ? void 0 : _36.gender) === "mu" &&
                            ((_37 = truck === null || truck === void 0 ? void 0 : truck.state.results) === null || _37 === void 0 ? void 0 : _37.section) === "climb", truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion, disabled: muClimbList.length == 0, pages: Math.floor((muClimbList.length - 1) / 10) + 1 }), _jsx("br", {}), _jsx("hr", {}), _jsx(ComponentButton, { title: "Calendar", id: "calendar", config: {}, active: (truck === null || truck === void 0 ? void 0 : truck.state.calendar) != null, truckId: truck === null || truck === void 0 ? void 0 : truck.id, truckVersion: truck === null || truck === void 0 ? void 0 : truck.stateVersion })] }))] })));
}
function validateSuper(superType, s) {
    var _a, _b;
    switch (superType) {
        case SuperType.LowerThird:
            if (!s.firstName) {
                throw new Error("Missing first name");
            }
            return {
                kind: superType,
                firstName: s.firstName,
                description: (_a = s.description) !== null && _a !== void 0 ? _a : null,
                logoId: (_b = s.logoId) !== null && _b !== void 0 ? _b : null,
            };
        case SuperType.GroupIdentifier:
            if (!s.text) {
                throw new Error("Missing text");
            }
            return {
                kind: superType,
                text: s.text,
            };
        case SuperType.Ticker:
            if (!s.text) {
                throw new Error("Missing text");
            }
            if (!s.logoId) {
                throw new Error("Missing logo id");
            }
            return {
                kind: superType,
                text: s.text,
                logoId: s.logoId,
            };
        case SuperType.PictureInPicture:
            if (!s.left) {
                throw new Error("Missing left text");
            }
            if (!s.right) {
                throw new Error("Missing right text");
            }
            return {
                kind: superType,
                left: s.left,
                right: s.right,
            };
        default:
            return undefined;
    }
}
function genderToRunnerClass(gender) {
    switch (gender) {
        case "men":
            return RunnerClass.Men;
        case "women":
            return RunnerClass.Women;
        case "mj":
            return RunnerClass.MU19;
        case "wj":
            return RunnerClass.WU19;
        case "mu":
            return RunnerClass.MU23;
        case "mu-crit":
            return RunnerClass.MU23Crit;
        case "men-crit":
            return RunnerClass.MenCrit;
        case "women-crit":
            return RunnerClass.WomenCrit;
    }
}
